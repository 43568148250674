.bg-img1L {
  background-image: url(../assets/img/ss-shape1L.png);
  background-size: 360px, 380px;
  background-position: bottom right;
}

.cal-bg-img {
  background-image: url(../assets/img/cal-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (min-width: 765px) {
  .vector-bg1 {
    background-image: url(../assets/img/vBG.svg);
    background-repeat: no-repeat;
    z-index: -10;
    background-size: contain;
    background-position: 60%;
  }
}

.nav-item {
  padding: 10px !important;
  height: 40px !important;
}

li a {
  color: #3d3636d2 !important;
  text-decoration: none;
  transition: color 0.3s !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  /* Set a fixed font size for the links */
  line-height: 24px;
  /* Set a fixed line height for the links */
  outline: none;
  box-shadow: none;
}

li a:hover {
  color: #000000e0 !important;
}

li a.active {
  color: #000 !important;
  border-bottom: #000000ca 2px solid !important;
}

@media screen and (min-width: 1920px) {
  /* For Projectors or Higher Resolution Screens (Full HD) */
  .bg-bg {
    margin-left: 20% !important;
    margin-right: 20% !important;
  }
}

